import { render, staticRenderFns } from "./OrderCourseContent.vue?vue&type=template&id=794ca9b9&scoped=true&"
import script from "./OrderCourseContent.vue?vue&type=script&lang=js&"
export * from "./OrderCourseContent.vue?vue&type=script&lang=js&"
import style0 from "./OrderCourseContent.vue?vue&type=style&index=0&id=794ca9b9&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "794ca9b9",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VFlex,VTab,VTabs})
